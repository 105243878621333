import { groupByKey } from '../../utility/util/util.service';

const initialState = {
  // Shared properties
  store: null, // current Store object
  menuEntries: null,
  menuEntriesData: null, // prepared for search
  menuCategoryTree: null, // prepared for sidebar
  foodAllergens: null,
  foodPreferences: null,
  foodIngredients: null,
  foodItemAdditions: null,
  foodAdditives: null,

  // User/Guest only
  table: null, // current Table object
  kiosk: null, // current Kiosk object
  cart: null, // Array of {hash: string} & OrderItem
  tableCart: null, // {tableId: string, openedAt: Date, updatedAt: Date, items: OrderItem[]}
  sharedCarts: [], // {displayName: string, customerProfileId: string, items: OrderItem[]}
  availableOrderTypes: null, // Array of orderTypes that are available during current visit
  checkout: {
    hash: null, // for validation if order changed
    orderType: null,
    checkoutMethod: null,
    deliveryAddressId: null,
    billingAddressId: null,
    cartComment: null,
    tip: 0,
    discountCode: null,
    paymentMethodFutureUsage: null,
    useRelevo: false,
  },
  checkoutBackup: null, // used to back up a checkout object (e.g. during pay&go to restore it afterward)
  orderHash: null, // hash of { cart: cart, checkout: checkout } to track changes
  order: null, // Order object

  // StoreOwner only
  itemCategories: null, // Array of ItemCategories
  terminals: null,
  printers: null,
  kiosks: null,
  tables: [],
  pickupStations: null,
  discountCodes: [],
  orderStats: {
    thisMonth: null,
    lastMonth: null,
    thisMonthExists: true,
    lastMonthExists: true,
    thisMonthPerDate: null,
  }, // stats for store
  storeOrders: null,
  serviceTimes: [],
  currentServiceTime: null,
  nextServiceTime: null,
  posIntegrations: null,
  posIntegrationItemDataMappings: null,
  posIntegrationItemAdditionMappings: null,
  posIntegrationTableMappings: null,
  additionGroups: null,
  connectors: null,
  areas: null,
  invoices: null,
  posIntegrationItemAdditionGroupMappings: null,
  // Admin only
  stores: null,
  allStoresStats: null,
  numberOfStores: null,
};

export default function store(state = initialState, action) {
  switch (action.type) {
    // this is intended for Store re-fetch (it updates only the Store, no flushing)
    case 'SET_STORE':
      return {
        ...state,
        store: action.payload,
      };
    // it's intended to be used on Store switch (all other data is getting flushed then)
    case 'SET_STORE_AND_FLUSH':
      return {
        ...initialState,
        store: action.payload,
        // keep the following properties, as they are globally valid, not related so specific stores
        foodAllergens: state.foodAllergens,
        foodPreferences: state.foodPreferences,
        foodIngredients: state.foodIngredients,
      };
    case 'SET_MENU_ENTRIES':
      sortItemDataByPrice(action.payload); // sort in place

      return {
        ...state,
        menuEntries: action.payload,
        menuCategoryTree: buildCategoryTree(action.payload),
      };
    case 'SET_MENU_ENTRIES_TEST':
      sortItemDataByPrice(action.payload); // sort in place

      return {
        ...state,
        menuEntriesTest: action.payload,
        menuCategoryTreeTest: buildCategoryTree(action.payload),
      };
    case 'SET_MENU_ENTRIES_DATA':
      return {
        ...state,
        menuEntriesData: action.payload,
      };
    case 'SET_ITEM_CATEGORIES':
      return {
        ...state,
        itemCategories: action.payload,
      };
    case 'SET_POS_INTEGRATIONS':
      return {
        ...state,
        posIntegrations: action.payload,
      };
    case 'SET_FOOD_ALLERGENS':
      return {
        ...state,
        foodAllergens: action.payload,
      };
    case 'SET_FOOD_ADDITIVES':
      return {
        ...state,
        foodAdditives: action.payload,
      };
    case 'SET_FOOD_PREFERENCES':
      return {
        ...state,
        foodPreferences: action.payload,
      };
    case 'SET_FOOD_INGREDIENTS':
      return {
        ...state,
        foodIngredients: action.payload,
      };
    case 'SET_FOOD_ITEM_ADDITIONS':
      return {
        ...state,
        foodItemAdditions: action.payload,
      };
    case 'SET_ADDITION_GROUPS':
      return {
        ...state,
        additionGroups: action.payload,
      };
    case 'SET_CART':
      return {
        ...state,
        cart: action.payload,
      };
    case 'SET_CHECKOUT':
      return {
        ...state,
        checkout:
          // set initial state if null was passed, but keep re-usable properties
          action.payload === null
            ? {
                ...initialState.checkout,
                orderType: state.checkout.orderType,
                checkoutMethod: state.checkout.checkoutMethod,
                deliveryAddressId: state.checkout.deliveryAddressId,
                billingAddressId: state.checkout.billingAddressId,
              }
            : { ...state.checkout, ...action.payload }, // partly update if not null
      };
    case 'SET_CHECKOUT_BACKUP':
      return {
        ...state,
        checkoutBackup: action.payload,
      };
    case 'SET_ORDER_HASH':
      return {
        ...state,
        orderHash: action.payload,
      };
    case 'SET_ORDER':
      return {
        ...state,
        order: action.payload,
      };
    case 'SET_STORE_ORDERS':
      return {
        ...state,
        storeOrders: [...action.payload]?.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        ),
      };
    case 'SET_STORE_SPECIFIC_ORDER':
      const existingOrderIndex = state.storeOrders.findIndex(
        (order) => order.id === action.payload.id,
      );
      if (existingOrderIndex !== -1) {
        // Replace the existing order with the action payload
        const updatedOrders = [...state.storeOrders];
        updatedOrders[existingOrderIndex] = action.payload;

        return {
          ...state,
          storeOrders: updatedOrders,
        };
      } else {
        // Add the action payload at the beginning of the orders array
        const newStoreOrders = [...state?.storeOrders, action.payload];
        return {
          ...state,
          storeOrders: newStoreOrders?.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          ),
        };
      }
    case 'SET_STORE_SPECIFIC_ORDERS':
      const existingOrderIndexInArray = state.storeOrders.findIndex(
        (order) => order.id === action.payload.id,
      );
      if (existingOrderIndexInArray !== -1) {
        // Replace the existing order with the action payload
        const updatedOrders = [...state.storeOrders];
        updatedOrders[existingOrderIndexInArray] = action.payload;

        return {
          ...state,
          storeOrders: updatedOrders,
        };
      } else {
        // Add the action payload at the beginning of the orders array
        const newStoreOrders = [...state?.storeOrders, ...action.payload];
        return {
          ...state,
          storeOrders: newStoreOrders?.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          ),
        };
      }
    case 'SET_TABLE':
      return {
        ...state,
        table: action.payload,
      };
    case 'SET_KIOSK':
      return {
        ...state,
        kiosk: action.payload,
      };
    case 'SET_ORDER_STATS':
      return {
        ...state,
        orderStats: action.payload,
      };
    case 'ADD_TABLES':
      const uniqueTables = action.payload.filter(
        (table) => !state.tables.some((t) => t.id === table.id),
      );

      return {
        ...state,
        tables: [...state.tables, ...uniqueTables],
      };
    case 'SET_SPECIFIC_TABLE':
      const updatedTables = state.tables.map((table) => {
        if (table.id === action.payload.id) {
          // Replace the existing table with action.payload
          return action.payload;
        }
        return table; // Keep the current table as is
      });

      // Check if the table was not found in updatedTables
      if (!updatedTables.some((table) => table.id === action.payload.id)) {
        // If the table was not found
        const newTable = Array.isArray(action.payload)
          ? action.payload
          : [action.payload];

        return {
          ...state,
          tables: [...newTable, ...updatedTables],
        };
      }

      return {
        ...state,
        tables: updatedTables,
      };

    case 'SET_TABLES':
      return {
        ...state,
        tables: action.payload,
      };
    case 'SET_KIOSKS':
      return {
        ...state,
        kiosks: action.payload,
      };
    case 'SET_PAYMENT_TERMINALS':
      return {
        ...state,
        terminals: action.payload,
      };
    case 'SET_DISCOUNT_CODE':
      return {
        ...state,
        discountCodes: action.payload,
      };
    case 'SET_SERVICE_TIMES':
      return {
        ...state,
        serviceTimes: action.payload,
      };
    case 'SET_CURRENT_SERVICE_TIME':
      return {
        ...state,
        currentServiceTime: action.payload,
      };
    case 'SET_NEXT_SERVICE_TIME':
      return {
        ...state,
        nextServiceTime: action.payload,
      };
    case 'SET_PRINTERS':
      const newPrinters = action.payload;
      return {
        ...state,
        printers: newPrinters?.sort((a, b) => a.name.localeCompare(b.name)),
      };
    case 'SET_PICKUP_STATIONS':
      const pickupStations = action.payload;
      return {
        ...state,
        pickupStations: pickupStations?.sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      };
    case 'SET_TABLE_CART':
      return {
        ...state,
        tableCart: action.payload,
      };
    /* case 'ADD_TABLE_CART_ITEMS':
      return {
        ...state,
        tableCart: [...state.tableCart, ...action.payload],
      }; */
    case 'SET_TABLE_CARTS':
      return {
        ...state,
        sharedCarts: action.payload,
      };
    case 'SET_POS_INTEGRATION_ITEM_DATA_MAPPINGS':
      return {
        ...state,
        posIntegrationItemDataMappings: action.payload,
      };
    case 'SET_POS_INTEGRATION_ITEM_ADDITION_MAPPINGS':
      return {
        ...state,
        posIntegrationItemAdditionMappings: action.payload,
      };
    case 'SET_POS_INTEGRATION_ITEM_ADDITION_GROUP_MAPPINGS':
      return {
        ...state,
        posIntegrationItemAdditionGroupMappings: action.payload,
      };
    case 'SET_POS_INTEGRATION_TABLE_MAPPINGS':
      return {
        ...state,
        posIntegrationTableMappings: action.payload,
      };
    case 'SET_ALL_STORES':
      return {
        ...state,
        stores: action.payload,
      };
    case 'SET_ALL_STORES_STATS':
      return {
        ...state,
        allStoresStats: action.payload,
      };
    case 'SET_NUMBER_OF_STORES':
      return {
        ...state,
        numberOfStores: action.payload,
      };
    case 'ADD_DISCOUNT_CODES':
      const uniqueCodes = action.payload.filter(
        (discountCode) =>
          !state.discountCodes.some((t) => t.id === discountCode.id),
      );

      return {
        ...state,
        discountCodes: [...state.discountCodes, ...uniqueCodes],
      };
    case 'SET_DISCOUNT_CODES':
      const newDiscountCodes = action.payload;
      return {
        ...state,
        discountCodes: newDiscountCodes?.sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      };
    case 'SET_SPECIFIC_DISCOUNT_CODE':
      const updatedDiscountCodes = state.discountCodes.map((discountCodes) => {
        if (discountCodes.id === action.payload.id) {
          // Replace the existing table with action.payload
          return action.payload;
        }
        return discountCodes; // Keep the current table as is
      });

      if (
        !updatedDiscountCodes.some(
          (discountCodes) => discountCodes.id === action.payload.id,
        )
      ) {
        // If the table was not found, add it to the end of the array
        updatedDiscountCodes.unshift(action.payload);
      }

      return {
        ...state,
        discountCode: updatedDiscountCodes,
      };
    case 'SET_SPECIFIC_STORES':
      const existingStoreIndexInArray = state.stores.findIndex(
        (store) => store.id === action.payload.id,
      );
      if (existingStoreIndexInArray !== -1) {
        // Replace the existing store with the action payload
        const updatedStores = [...state.stores];
        updatedStores[existingStoreIndexInArray] = action.payload;

        return {
          ...state,
          stores: updatedStores,
        };
      } else {
        // Add the action payload at the beginning of the stores array
        const newStoreStores = [...state?.stores, ...action.payload];
        return {
          ...state,
          stores: newStoreStores?.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          ),
        };
      }
    case 'SET_AVAILABLE_ORDER_TYPES':
      return {
        ...state,
        availableOrderTypes: action.payload,
      };

    case 'SET_CONNECTORS':
      return {
        ...state,
        connectors: action.payload,
      };
    case 'SET_AREAS':
      return {
        ...state,
        areas: action.payload,
      };
    case 'SET_INVOICES':
      return {
        ...state,
        invoices: action.payload,
      };
    case 'SET_MERCHANT_PROFILE':
      return {
        ...state,
        store: { ...state.store, merchantProfile: action.payload },
      };
    default:
      return state;
  }
}

const sortItemDataByPrice = (menuEntries) =>
  menuEntries?.forEach(({ itemEntry }) =>
    itemEntry?.data.sort((a, b) => a.price - b.price),
  );

const mapCategoryMenuEntriesByParent = (menuEntries) =>
  groupByKey(
    menuEntries
      ?.filter(({ type }) => type === 'CategoryMenuEntry')
      .map((categoryMenuEntry) => ({
        ...categoryMenuEntry,
        parent: categoryMenuEntry.category?.id || 'root',
      })),
    'parent',
  );

const buildCategoryTree = (menuEntries) => {
  const categoriesByParent = mapCategoryMenuEntriesByParent(menuEntries);

  return categoriesByParent['root']?.map(({ categoryEntry }) => ({
    ...categoryEntry,
    children:
      categoriesByParent[categoryEntry.id]?.map(
        ({ categoryEntry }) => categoryEntry,
      ) || null,
  }));
};
