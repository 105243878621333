// Routes
export const ROUTE_ERROR_404 = '/404';
export const ROUTE_ERROR_500 = '/500';
export const ROUTE_SIGNUP = '/signup';
export const ROUTE_SIGNUP_STORE = ROUTE_SIGNUP + '/store';
export const ROUTE_STORE = '/store';
export const ROUTE_TABLE = '/table';
export const ROUTE_KIOSK = '/kiosk';
export const ROUTE_ADMIN = '/admin';
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_DASHBOARD_SETTINGS = ROUTE_DASHBOARD + '/settings';
export const ROUTE_DASHBOARD_ORDERS = ROUTE_DASHBOARD + '/orders';

// Order Types
export const ORDER_TYPE_DELIVERY = 'DeliveryOrder';
export const ORDER_TYPE_PICKUP = 'PickupOrder';
export const ORDER_TYPE_INHOUSE = 'InhouseOrder';
export const ORDER_TYPE_CATERING = 'CateringOrder';
export const ORDER_TYPE_SELF_SERVICE = 'SelfServiceOrder';

// Checkout Methods
export const CHECKOUT_METHOD_ORDER_AND_PAY = 'order_and_pay';
export const CHECKOUT_METHOD_ORDER_ONLY = 'order_only';
export const CHECKOUT_METHOD_PAY_AND_GO = 'pay_and_go';

// Addresses
export const DELIVERY_ADDRESS = 'DeliveryAddress';
export const BILLING_ADDRESS = 'BillingAddress';

// Order states
export const ORDER_STATUS_PENDING = 'pending';
export const ORDER_STATUS_PAID = 'paid';
export const ORDER_STATUS_PROCESSING = 'processing';
export const ORDER_STATUS_PREPARING = 'preparing';
export const ORDER_STATUS_PREPARED = 'prepared';
export const ORDER_STATUS_DELIVERING = 'delivering';
export const ORDER_STATUS_COMPLETED = 'completed';
export const ORDER_STATUS_CANCELLED = 'cancelled';
export const ORDER_STATUS_REFUNDED = 'refunded';
export const ORDER_STATUS_DISPUTED = 'disputed';

export const integrations = [
  { name: 'Stripe', type: 'PaymentProcessor', brand: 'stripe' },
  { name: 'HelloTESS!', type: 'HelloTessPosIntegration', brand: 'hellotess' },
  {
    name: 'bonit',
    type: 'MergeportPosIntegration',
    brand: 'bonit',
  },
  {
    name: 'OktoPOS',
    type: 'MergeportPosIntegration',
    brand: 'oktopos',
  },
  {
    name: 'Speedy',
    type: 'MergeportPosIntegration',
    brand: 'speedy',
  },
  {
    name: '[rs]POS',
    type: 'MergeportPosIntegration',
    brand: 'rspos',
  },
  {
    name: 'KRO4PRO',
    type: 'MergeportPosIntegration',
    brand: 'kro4pro',
  },
  {
    name: 'Naramis',
    type: 'MergeportPosIntegration',
    brand: 'naramis',
  },
  {
    name: 'WBV POS',
    type: 'MergeportPosIntegration',
    brand: 'wbv_pos',
  },
  {
    name: 'GastroSoft',
    type: 'MergeportPosIntegration',
    brand: 'gastrosoft',
  },
  {
    name: 'CashIT',
    type: 'MergeportPosIntegration',
    brand: 'cashit',
  },
  {
    name: 'Xenia',
    type: 'MergeportPosIntegration',
    brand: 'xenia',
  },
  {
    name: 'PC Cash',
    type: 'MergeportPosIntegration',
    brand: 'pc_cash',
  },
  {
    name: 'Vectron',
    type: 'MergeportPosIntegration',
    brand: 'vectron',
  },
  {
    name: 'Lina',
    type: 'MergeportPosIntegration',
    brand: 'lina',
  },
  {
    name: 'WinOrder',
    type: 'MergeportPosIntegration',
    brand: 'winorder',
  },
  {
    name: 'Tipos',
    type: 'MergeportPosIntegration',
    brand: 'tipos',
  },
  { name: 'SCHULTES', type: 'MergeportPosIntegration', brand: 'schultes' },
  {
    name: 'SmartSchank',
    type: 'SmartSchankPeripheralIntegration',
    brand: 'smart_schank',
  },
  {
    name: 'GoogleTagManager',
    type: 'GoogleTagManagerPeripheralIntegration',
    brand: 'google_tag',
  },
  {
    name: 'Hubrise',
    type: 'HubrisePosIntegration',
    brand: 'lightspeed',
  },
  {
    name: '3S POS',
    type: 'HubrisePosIntegration',
    brand: '3s_pos',
  },
  {
    name: 'Adipos',
    type: 'HubrisePosIntegration',
    brand: 'adipos',
  },
  {
    name: 'Carré POS',
    type: 'HubrisePosIntegration',
    brand: 'carre_pos',
  },
  {
    name: 'Clyo Systems',
    type: 'HubrisePosIntegration',
    brand: 'clyo_systems',
  },
  {
    name: 'EPOS Technologies',
    type: 'HubrisePosIntegration',
    brand: 'epos_technologies',
  },
  {
    name: 'French Caisse',
    type: 'HubrisePosIntegration',
    brand: 'french_aisse',
  },
  {
    name: 'eTakeaway Max',
    type: 'HubrisePosIntegration',
    brand: 'etakeaway_max',
  },
  {
    name: 'iiko',
    type: 'HubrisePosIntegration',
    brand: 'iiko',
  },
  {
    name: 'Jalia',
    type: 'HubrisePosIntegration',
    brand: 'jalia',
  },
  {
    name: 'Kezia II',
    type: 'HubrisePosIntegration',
    brand: 'kezia_ii',
  },
  {
    name: 'LEO2',
    type: 'HubrisePosIntegration',
    brand: 'leo2',
  },
  {
    name: 'Lightspeed (K-Series) (H)',
    type: 'HubrisePosIntegration',
    brand: 'lightspeed_kseries',
  },
  {
    name: 'Mealenium',
    type: 'HubrisePosIntegration',
    brand: 'mealenium',
  },
  {
    name: 'MiiPos',
    type: 'HubrisePosIntegration',
    brand: 'miipos',
  },
  {
    name: 'MyOrderBox',
    type: 'HubrisePosIntegration',
    brand: 'myorderbox',
  },
  {
    name: 'Nestor',
    type: 'HubrisePosIntegration',
    brand: 'nestor',
  },
  {
    name: 'Next Order',
    type: 'HubrisePosIntegration',
    brand: 'next_order',
  },
  {
    name: 'OrderLord',
    type: 'HubrisePosIntegration',
    brand: 'orderlord',
  },
  {
    name: 'OrderWire',
    type: 'HubrisePosIntegration',
    brand: 'orderwire',
  },
  {
    name: 'Panda EPOS',
    type: 'HubrisePosIntegration',
    brand: 'panda_epos',
  },
  {
    name: 'PAR PixelPoint',
    type: 'HubrisePosIntegration',
    brand: 'par_pixelpoint',
  },
  {
    name: 'pointOne',
    type: 'HubrisePosIntegration',
    brand: 'pointone',
  },
  {
    name: 'Popina',
    type: 'HubrisePosIntegration',
    brand: 'popina',
  },
  {
    name: 'POS HELPER',
    type: 'HubrisePosIntegration',
    brand: 'pos_helper',
  },
  {
    name: 'Restaurant Manager',
    type: 'HubrisePosIntegration',
    brand: 'restaurant_manager',
  },
  {
    name: 'Synapsy',
    type: 'HubrisePosIntegration',
    brand: 'synapsy',
  },
  {
    name: 'SICOM',
    type: 'HubrisePosIntegration',
    brand: 'sicom',
  },
  {
    name: 'AKK EPOS',
    type: 'HubrisePosIntegration',
    brand: 'akk_epos',
  },
  {
    name: 'SwooPOS',
    type: 'HubrisePosIntegration',
    brand: 'swoopos',
  },
  {
    name: 'Talc',
    type: 'HubrisePosIntegration',
    brand: 'talc',
  },
  {
    name: 'TASK',
    type: 'HubrisePosIntegration',
    brand: 'task',
  },
  {
    name: 'UltimaPOS',
    type: 'HubrisePosIntegration',
    brand: 'ultimapos',
  },
  {
    name: 'Zelty',
    type: 'HubrisePosIntegration',
    brand: 'zelty',
  },
  {
    name: 'Clover',
    type: 'DeliverectPosIntegration',
    brand: 'clover',
  },
  {
    name: 'Floid',
    type: 'DeliverectPosIntegration',
    brand: 'floid',
  },
  {
    name: 'Floid',
    type: 'DeliverectPosIntegration',
    brand: 'floid',
  },
  {
    name: 'Lightspeed (D)',
    type: 'DeliverectPosIntegration',
    brand: 'lightspeed',
  },
  {
    name: 'Micros Simphony',
    type: 'DeliverectPosIntegration',
    brand: 'micros_simphony',
  },
  {
    name: 'NCR Aloha',
    type: 'DeliverectPosIntegration',
    brand: 'ncr_aloha',
  },
  {
    name: 'Touch Bistro',
    type: 'DeliverectPosIntegration',
    brand: 'touch_bistro',
  },
  {
    name: 'S4D',
    type: 'DeliverectPosIntegration',
    brand: 's4d',
  },
  {
    name: 'Gastronovi',
    type: 'DeliverectPosIntegration',
    brand: 'gastronovi',
  },
  {
    name: 'CapInfo',
    type: 'DeliverectPosIntegration',
    brand: 'Cap_info',
  },
  {
    name: 'DizLog',
    type: 'DeliverectPosIntegration',
    brand: 'dizlog',
  },
  {
    name: 'EasyShop',
    type: 'DeliverectPosIntegration',
    brand: 'easyshop',
  },
  {
    name: 'Epos Now',
    type: 'DeliverectPosIntegration',
    brand: 'epos_now',
  },
  {
    name: 'Guest line',
    type: 'DeliverectPosIntegration',
    brand: 'guest_line',
  },
  {
    name: 'Hippos',
    type: 'DeliverectPosIntegration',
    brand: 'hippos',
  },
  {
    name: 'Hypersoft',
    type: 'DeliverectPosIntegration',
    brand: 'hypersoft',
  },
  {
    name: 'Lightspeed G-Series',
    type: 'DeliverectPosIntegration',
    brand: 'lightspeed_gseries',
  },
  {
    name: 'LINA TeamCloud',
    type: 'DeliverectPosIntegration',
    brand: 'lina_teamcloud',
  },
  {
    name: 'Micros RES 3700',
    type: 'DeliverectPosIntegration',
    brand: 'micros_res_3700',
  },
  {
    name: 'Orderbird',
    type: 'DeliverectPosIntegration',
    brand: 'orderbird',
  },
  {
    name: 'Restolution',
    type: 'DeliverectPosIntegration',
    brand: 'restolution',
  },
  {
    name: 'Restolution',
    type: 'DeliverectPosIntegration',
    brand: 'restolution',
  },
  {
    name: 'Shiji Group',
    type: 'DeliverectPosIntegration',
    brand: 'shiji_group',
  },
  {
    name: 'Starrtec Solutions',
    type: 'DeliverectPosIntegration',
    brand: 'starrtec_solutions',
  },
  {
    name: 'Tilby',
    type: 'DeliverectPosIntegration',
    brand: 'tilby',
  },
].sort((a, b) => a.name.localeCompare(b.name));

export const SyncItemCategoryProperties = Object.freeze(['name', 'image']);

export const SyncItemProperties = Object.freeze([
  'name',
  'price',
  'tax',
  'image',
  'description',
  'order_conditions',
  'is_active',
]);

export const SyncItemAdditionProperties = Object.freeze([
  'name',
  'price',
  'tax',
  'description',
  'order_conditions',
  'is_active',
]);

export const SyncItemAdditionGroupProperties = Object.freeze([
  'name',
  'order_conditions',
  'structure',
  'logic',
  'is_active',
]);

export const SyncTableProperties = Object.freeze([
  'name',
  'capacity',
  'is_active',
]);
