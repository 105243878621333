/*
This reducer is used to manage the modal states. The main idea is, that any modal should
have a "isOpen" and (optionally) a data state. As it is not possible to open 2 or more
modals at the same time, any "isOpen" dispatch should set all others to false. Also, a
"data" dispatch should set null to all other data states.
 */

// separate isOpen states to be able to close all modals at once
const initialModalOpenState = {
  isCreateCategoryModalOpen: false,
  isEditCategoryModalOpen: false,
  isCreateItemModalOpen: false,
  isEditItemModalOpen: false,
  isCreateAdditionModalOpen: false,
  isSelectAdditionsModalOpen: false,
  isListAdditionsModalOpen: false,
  isEditAdditionModalOpen: false,
  isItemInfoModalOpen: false,
  isSetFiltersModalOpen: false,
  isCartModalOpen: false,
  isCheckoutModalOpen: false,
  isPickupStatusModalOpen: false,
  isEditMenuModalOpen: false,
  isTipModalOpen: false,
  isWhatsappModalOpen: false,
  isListOrdersModalOpen: false,
  isMoreSettingsModalOpen: false,
  isEditStoreLogoModalOpen: false,
  isOrderInfoModalOpen: false,
  isEditTableModalOpen: false,
  isCreateTableModalOpen: false,
  isEditPaymentMethodModalOpen: false,
  isListPaymentMethodsModalOpen: false,
  isCreatePaymentMethodModalOpen: false,
  isEditMenuEntryCategoryModalOpen: false,
  isCreateBillingAddressModalOpen: false,
  isEditBillingAddressModalOpen: false,
  isCreateDeliveryAddressModalOpen: false,
  isEditDeliveryAddressModalOpen: false,
  isListAddressesModalOpen: false,
  isUserSettingsModalOpen: false,
  isCreatePreferenceModalOpen: false,
  isCreateIngredientModalOpen: false,
  isCreateAllergenModalOpen: false,
  isCreateAdditiveModalOpen: false,
  isEditPreferenceModalOpen: false,
  isEditIngredientModalOpen: false,
  isEditAllergenModalOpen: false,
  isEditAdditiveModalOpen: false,
  isSelectBillingAddressModalOpen: false,
  isTableCartModalOpen: false,
  isCreateHelloTessPosIntegrationModalOpen: false,
  isEditHelloTessPosIntegrationModalOpen: false,
  isCreateMergePortPosIntegrationModalOpen: false,
  isEditMergePortPosIntegrationModalOpen: false,
  isEditStripeIntegrationModalOpen: false,
  isEditMerchantProfileModalOpen: false,
  isCreateSmartSchankIntegrationModalOpen: false,
  isEditSmartSchankIntegrationModalOpen: false,
  isCreateGoogleTagManagerIntegrationModalOpen: false,
  isEditGoogleTagManagerIntegrationModalOpen: false,
  isCreateInfoModalOpen: false,
  isEditInfoModalOpen: false,
  isEditDiscountCodesModalOpen: false,
  isCreateDiscountCodesModalOpen: false,
  isTableReservationModalOpen: false,
  isSignUpNoticeModalOpen: false,
  isIncomingOrderModalOpen: false,
  incomingOrderConfirmationModalOpen: false,
  isCreateOrderConditionsModalOpen: false,
  isEditOrderConditionsModalOpen: false,
  isCreateItemAdditionGroupModalOpen: false,
  isEditItemAdditionGroupModalOpen: false,
  isEditCategoryBulkModalOpen: false,
  isCreateKioskModalOpen: false,
  isEditKioskModalOpen: false,
  isCreateTerminalModalOpen: false,
  isEditTerminalModalOpen: false,
  isCreatePickupStationModalOpen: false,
  isEditPickupStationModalOpen: false,
  isCreateMatrixPosIntegrationModalOpen: false,
  isEditMatrixPosIntegrationModalOpen: false,
  isCreateConnectorModalOpen: false,
  isEditConnectorModalOpen: false,
  isCreateScannerIntegrationModalOpen: false,
  isEditScannerIntegrationModalOpen: false,
  isCreateDeliverectPosIntegrationModalOpen: false,
  isEditDeliverectPosIntegrationModalOpen: false,
  isCallWaiterModalOpen: false,
};

// seperate modal data states to be able to reset all modal data at once
const initialModalDataState = {
  /*{
    category: null,
    parentCategoryId: null, // mandatory
    onSuccess: null,
  },*/
  createCategoryModalData: null,
  /*{
    category: null, // mandatory
    onSuccess: null,
  },*/
  editCategoryModalData: null,
  /*{
    isVariation: false, // mandatory
    targetCategoryId: null, // mandatory when isVariation=false
    targetItemId: null, // mandatory when isVariation=true
    itemData: null, // mandatory when isVariation=true
    onSuccess: null,
  },*/
  createItemModalData: null,
  /*{
    isVariation: false, // mandatory
    itemData: null, // mandatory
    onSuccess: null,
  },*/
  editItemModalData: null,

  editItemModalDataAvailableAt: null,
  /*{
    addition: null, // mandatory
    onSuccess: null,
  },*/
  createAdditionModalData: null,
  /*{
    addition: null, // mandatory
    onSuccess: null,
  },*/
  editAdditionModalData: null,
  /*{
  itemData: null, // mandatory
  onSuccess: null,
},*/
  selectAdditionsModalData: null,
  /*{
    itemData: null, // mandatory
    onSuccess: null,
  },*/
  itemInfoModalData: null,
  /*{
  currently not defined. Is this needed? Maybe for sorting/excluding purposes?
},*/
  listAdditionsModalData: null,
  /*{
  currently not defined. Use maybe for preselecting tip value?
},*/
  tipModalData: null,
  /*{
    onlyPending: false
  },*/
  listOrdersModalData: null,
  /*{
  currently not defined.
  },*/
  moreSettingsModalData: null,
  /*{
  currently not defined.
  },*/
  editStoreLogoModalData: null,
  /*{
  order: null, // mandatory
  },*/
  orderInfoModalData: null,
  /*{
  table: null, // mandatory
  },*/
  editTableModalData: null,
  //currently not defined
  createTableModalData: null,

  createPaymentMethodModalData: null,

  editPaymentMethodModalData: null,

  listPaymentMethodsModalData: null,

  editMenuEntryCategoryModalData: null,
  /*{
  billingAddress: null, // optional
  onSuccess: null,
},*/
  createBillingAddressModalData: null,
  /*{
  billingAddress: null, // mandatory
  onSuccess: null,
  },*/
  editBillingAddressModalData: null,
  /*{
    deliveryAddress: null, // optional
    onSuccess: null,
  },*/
  createDeliveryAddressModalData: null,
  /*{
    deliveryAddress: null, // mandatory
    onSuccess: null,
  },*/
  editDeliveryAddressModalData: null,
  /*{type: string} one of: BILLING_ADDRESS or DELIVERY_ADDRESS*/
  listAddressesModalData: null,
  /*{
    preference: null, // optional
    onSuccess: null,
  },*/
  editPreferenceModalData: null,
  /*{
    ingredient: null, // optional
    onSuccess: null,
  },*/
  editIngredientModalData: null,
  /*{
    allergen: null, // optional
    onSuccess: null,
  },*/
  editAllergenModalData: null,
  /*{
   //edit this later
  },*/
  editAdditiveModalData: null,
  /*{
   //edit this later
  },*/
  selectBillingAddressModalData: null,
  /*{
//edit this later
},*/
  tableCartModalData: null,

  createHelloTessPosIntegrationModalData: null,

  editHelloTessPosIntegrationModalData: null,

  createMergePortPosIntegrationModalData: null,

  editMergePortPosIntegrationModalData: null,

  editStripeIntegrationModalData: null,

  editMerchantProfileModalData: null,

  createSmartSchankIntegrationModalData: null,

  editSmartSchankIntegrationModalData: null,

  createGoogleTagManagerIntegrationModalData: null,

  editGoogleTagManagerIntegrationModalData: null,

  createInfoModalData: null,

  editInfoModalData: null,

  infoModalData: null,
  editDiscountCodesModalData: null,
  //currently not defined
  createDiscountCodesModalData: null,

  isIncomingOrderConfirmationModalData: null,

  createOrderConditionsModalData: null,

  editOrderConditionsModalData: null,

  createItemAdditionGroupModalData: null,

  editItemAdditionGroupModalData: null,

  editCategoryBulkModalData: null,

  createKioskModalData: null,

  editKioskModalData: null,

  createTerminalModalData: null,

  editTerminalModalData: null,

  createPickupStationModalData: null,

  editPickupStationModalData: null,

  createMatrixPosIntegrationModalData: null,

  editMatrixPosIntegrationModalData: null,

  createConnectorModalData: null,

  editConnectorModalData: null,

  createScannerIntegrationModalData: null,

  editScannerIntegrationModalData: null,

  createDeliverectPosIntegrationModalData: null,
  editDeliverectPosIntegrationModalData: null,
};

// combined initial state
const initialState = { ...initialModalOpenState, ...initialModalDataState };

export default function modals(state = initialState, action) {
  switch (action.type) {
    // manages the category modal (admin only)
    case 'SET_IS_CREATE_CATEGORY_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateCategoryModalOpen: action.payload,
      };
    case 'SET_CREATE_CATEGORY_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createCategoryModalData: action.payload,
      };
    case 'SET_IS_EDIT_CATEGORY_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditCategoryModalOpen: action.payload,
      };
    case 'SET_EDIT_CATEGORY_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editCategoryModalData: action.payload,
      };

    // manages the item modal (admin only)
    case 'SET_IS_CREATE_ITEM_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateItemModalOpen: action.payload,
      };
    case 'SET_CREATE_ITEM_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createItemModalData: action.payload,
      };
    case 'SET_IS_EDIT_ITEM_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditItemModalOpen: action.payload,
      };
    case 'SET_EDIT_ITEM_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editItemModalData: action.payload,
      };
    case 'SET_EDIT_ITEM_MODAL_DATA_AVAILABLE_AT':
      return {
        ...state,
        /* ...initialModalDataState, // reset all modal data */
        editItemModalDataAvailableAt: action.payload,
      };

    // manages the addition modal (admin only)
    case 'SET_IS_CREATE_ADDITION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateAdditionModalOpen: action.payload,
      };
    case 'SET_CREATE_ADDITION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createAdditionModalData: action.payload,
      };
    case 'SET_IS_EDIT_ADDITION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditAdditionModalOpen: action.payload,
      };
    case 'SET_EDIT_ADDITION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editAdditionModalData: action.payload,
      };
    case 'SET_SELECT_ADDITIONS_MODAL_DATA':
      return {
        ...state,
        selectAdditionsModalData: action.payload,
      };
    case 'SET_IS_SELECT_ADDITIONS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isSelectAdditionsModalOpen: action.payload,
      };
    case 'SET_LIST_ADDITIONS_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        listAdditionsModalData: action.payload,
      };
    case 'SET_IS_LIST_ADDITIONS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isListAdditionsModalOpen: action.payload,
      };

    // manages the item info modal
    case 'SET_ITEM_INFO_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        itemInfoModalData: action.payload,
      };
    case 'SET_IS_ITEM_INFO_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isItemInfoModalOpen: action.payload,
      };

    // manages the set filters modal
    case 'SET_IS_SET_FILTERS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isSetFiltersModalOpen: action.payload,
      };

    // manages the cart modal
    case 'SET_IS_CART_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCartModalOpen: action.payload,
      };

    // manages the checkout modal
    case 'SET_IS_CHECKOUT_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCheckoutModalOpen: action.payload,
      };

    // manages the pickup status modal
    case 'SET_IS_PICKUP_STATUS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isPickupStatusModalOpen: action.payload,
      };

    // manages the edit menu modal
    case 'SET_IS_EDIT_MENU_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditMenuModalOpen: action.payload,
      };

    // manages the tip modal
    case 'SET_IS_TIP_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isTipModalOpen: action.payload,
      };
    case 'SET_IS_WHATSUP_MODAL_OPEN':
      return {
        ...state,
        /*         ...initialModalOpenState, // close all modals */
        isWhatsappModalOpen: action.payload,
      };
    case 'SET_TIP_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        tipModalData: action.payload,
      };

    // manages the list orders modal
    case 'SET_IS_LIST_ORDERS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isListOrdersModalOpen: action.payload,
      };
    case 'SET_LIST_ORDERS_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        listOrdersModalData: action.payload,
      };

    // manages the moresettings modal
    case 'SET_IS_MORE_SETTINGS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isMoreSettingsModalOpen: action.payload,
      };
    case 'SET_MORE_SETTINGS_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        listMoreSettingsModalData: action.payload,
      };

    // manages the edit store logo modal
    case 'SET_IS_EDIT_STORE_LOGO_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditStoreLogoModalOpen: action.payload,
      };
    case 'SET_EDIT_STORE_LOGO_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editStoreLogoModalData: action.payload,
      };

    // manages the order info modal
    case 'SET_IS_ORDER_INFO_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isOrderInfoModalOpen: action.payload,
      };
    case 'SET_ORDER_INFO_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        orderInfoModalData: { ...state.orderInfoModalData, ...action.payload },
      };
    case 'SET_IS_CREATE_TABLE_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateTableModalOpen: action.payload,
      };
    case 'SET_CREATE_TABLE_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createTableModalData: action.payload,
      };
    case 'SET_IS_EDIT_TABLE_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditTableModalOpen: action.payload,
      };
    case 'SET_EDIT_TABLE_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editTableModalData: action.payload,
      };
    case 'SET_IS_CREATE_PRINTER_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreatePrinterModalOpen: action.payload,
      };
    case 'SET_CREATE_PRINTER_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createPrinterModalData: action.payload,
      };
    case 'SET_IS_EDIT_PRINTER_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditPrinterModalOpen: action.payload,
      };
    case 'SET_EDIT_PRINTER_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editPrinterModalData: action.payload,
      };
    case 'SET_CREATE_PAYMENT_METHOD_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createPaymentMethodModalData: action.payload,
      };
    case 'SET_IS_EDIT_PAYMENT_METHOD_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditPaymentMethodModalOpen: action.payload,
      };
    case 'SET_EDIT_PAYMENT_METHOD_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editPaymentMethodModalData: action.payload,
      };
    case 'SET_LIST_PAYMENT_METHODS_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        listPaymentMethodsModalData: action.payload,
      };
    case 'SET_IS_LIST_PAYMENT_METHODS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isListPaymentMethodsModalOpen: action.payload,
      };
    case 'SET_IS_CREATE_PAYMENT_METHODS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreatePaymentMethodModalOpen: action.payload,
      };
    case 'SET_IS_EDIT_ITEM_CATEGORY_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState,
        isEditMenuEntryCategoryModalOpen: action.payload,
      };
    case 'SET_EDIT_ITEM_CATEGORY_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState,
        editMenuEntryCategoryModalData: action.payload,
      };
    case 'SET_IS_LIST_ADDRESSES_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // reset all modal data
        isListAddressesModalOpen: action.payload,
      };
    case 'SET_IS_USER_SETTINGS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // reset all modal data
        isUserSettingsModalOpen: action.payload,
      };
    case 'SET_LIST_ADDRESSES_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        listAddressesModalData: action.payload,
      };
    case 'SET_IS_CREATE_BILLING_ADDRESS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateBillingAddressModalOpen: action.payload,
      };
    case 'SET_CREATE_BILLING_ADDRESS_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        orderInfoModalData: state.orderInfoModalData,
        createBillingAddressModalData: action.payload,
      };
    case 'SET_IS_EDIT_BILLING_ADDRESS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditBillingAddressModalOpen: action.payload,
      };
    case 'SET_EDIT_BILLING_ADDRESS_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editBillingAddressModalData: action.payload,
      };
    case 'SET_IS_CREATE_DELIVERY_ADDRESS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateDeliveryAddressModalOpen: action.payload,
      };
    case 'SET_CREATE_DELIVERY_ADDRESS_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createDeliveryAddressModalData: action.payload,
      };
    case 'SET_IS_EDIT_DELIVERY_ADDRESS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditDeliveryAddressModalOpen: action.payload,
      };
    case 'SET_EDIT_DELIVERY_ADDRESS_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editDeliveryAddressModalData: action.payload,
      };
    case 'SET_IS_CREATE_PREFERENCE_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreatePreferenceModalOpen: action.payload,
      };
    case 'SET_IS_EDIT_PREFERENCE_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditPreferenceModalOpen: action.payload,
      };
    case 'SET_EDIT_PREFERENCE_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editPreferenceModalData: action.payload,
      };
    case 'SET_IS_CREATE_INGREDIENT_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateIngredientModalOpen: action.payload,
      };
    case 'SET_IS_EDIT_INGREDIENT_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditIngredientModalOpen: action.payload,
      };
    case 'SET_EDIT_INGREDIENT_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editIngredientModalData: action.payload,
      };
    case 'SET_IS_CREATE_ALLERGEN_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateAllergenModalOpen: action.payload,
      };
    case 'SET_IS_EDIT_ALLERGEN_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditAllergenModalOpen: action.payload,
      };
    case 'SET_EDIT_ALLERGEN_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editAllergenModalData: action.payload,
      };
    case 'SET_IS_CREATE_ADDITIVE_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateAdditiveModalOpen: action.payload,
      };
    case 'SET_IS_EDIT_ADDITIVE_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditAdditiveModalOpen: action.payload,
      };
    case 'SET_EDIT_ADDITIVE_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editAdditiveModalData: action.payload,
      };
    case 'SET_SELECT_BILLING_ADDRESS_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // close all modals
        selectBillingAddressModalData: action.payload,
      };
    case 'SET_SELECT_BILLING_ADDRESS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isSelectBillingAddressModalOpen: action.payload,
      };
    case 'SET_TABLE_CART_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // close all modals
        tableCartModalData: action.payload,
      };
    case 'SET_IS_TABLE_CART_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isTableCartModalOpen: action.payload,
      };
    case 'SET_IS_CREATE_HELLO_TESS_POS_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateHelloTessPosIntegrationModalOpen: action.payload,
      };
    case 'SET_CREATE_HELLO_TESS_POS_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createHelloTessPosIntegrationModalData: action.payload,
      };
    case 'SET_IS_EDIT_HELLO_TESS_POS_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditHelloTessPosIntegrationModalOpen: action.payload,
      };
    case 'SET_EDIT_HELLO_TESS_POS_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editHelloTessPosIntegrationModalData: action.payload,
      };
    case 'SET_IS_CREATE_MERGE_PORT_POS_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateMergePortPosIntegrationModalOpen: action.payload,
      };
    case 'SET_CREATE_MERGE_PORT_POS_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createMergePortPosIntegrationModalData: action.payload,
      };
    case 'SET_IS_EDIT_MERGE_PORT_POS_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditMergePortPosIntegrationModalOpen: action.payload,
      };
    case 'SET_EDIT_MERGE_PORT_POS_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editMergePortPosIntegrationModalData: action.payload,
      };
    case 'SET_IS_EDIT_STRIPE_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditStripeIntegrationModalOpen: action.payload,
      };
    case 'SET_EDIT_STRIPE_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editStripeIntegrationModalData: action.payload,
      };
    case 'SET_IS_EDIT_MERCHANT_PROFILE_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditMerchantProfileModalOpen: action.payload,
      };
    case 'SET_EDIT_MERCHANT_PROFILE_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editMerchantProfileModalData: action.payload,
      };
    case 'SET_IS_CREATE_DIRMEINER_SMART_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateSmartSchankIntegrationModalOpen: action.payload,
      };
    case 'SET_CREATE_DIRMEINER_SMART_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createSmartSchankIntegrationModalData: action.payload,
      };
    case 'SET_IS_EDIT_DIRMEINER_SMART_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditSmartSchankIntegrationModalOpen: action.payload,
      };
    case 'SET_EDIT_DIRMEINER_SMART_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editSmartSchankIntegrationModalData: action.payload,
      };
    case 'SET_IS_CREATE_GOOGLE_TAG_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateGoogleTagManagerIntegrationModalOpen: action.payload,
      };
    case 'SET_CREATE_GOOGLE_TAG_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createGoogleTagManagerIntegrationModalData: action.payload,
      };
    case 'SET_IS_EDIT_GOOGLE_TAG_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditGoogleTagManagerIntegrationModalOpen: action.payload,
      };
    case 'SET_EDIT_GOOGLE_TAG_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editGoogleTagManagerIntegrationModalData: action.payload,
      };
    case 'SET_IS_INFO_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isInfoModalOpen: action.payload,
      };
    case 'SET_INFO_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // close all modals
        infoModalData: action.payload,
      };
    case 'SET_IS_CREATE_INFO_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateInfoModalOpen: action.payload,
      };
    case 'SET_CREATE_INFO_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createInfoModalData: action.payload,
      };
    case 'SET_IS_EDIT_INFO_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditInfoModalOpen: action.payload,
      };
    case 'SET_EDIT_INFO_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editInfoModalData: action.payload,
      };

    case 'SET_IS_CREATE_DISCOUNT_CODES_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateDiscountCodesModalOpen: action.payload,
      };
    case 'SET_CREATE_DISCOUNT_CODES_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createDiscountCodesModalData: action.payload,
      };
    case 'SET_IS_EDIT_DISCOUNT_CODES_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditDiscountCodesModalOpen: action.payload,
      };
    case 'SET_EDIT_DISCOUNT_CODES_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editDiscountCodesModalData: action.payload,
      };
    case 'SET_IS_TABLE_RESERVATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // reset all modal data
        isTableReservationModalOpen: action.payload,
      };
    case 'SET_IS_INCOMING_ORDER_MODAL_OPEN':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        isIncomingOrderConfirmationModalOpen: action.payload,
      };
    case 'SET_IS_INCOMING_ORDER_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        incomingOrderConfirmationModalData: action.payload,
      };
    case 'SET_IS_SIGN_UP_NOTICE_MODAL_OPEN':
      return {
        ...state,
        isSignUpNoticeModalOpen: action.payload,
      };
    case 'SET_IS_CREATE_HUBRISE_POS_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateHubrisePosIntegrationModalOpen: action.payload,
      };
    case 'SET_CREATE_HUBRISE_POS_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createHubrisePosIntegrationModalData: action.payload,
      };
    case 'SET_IS_EDIT_HUBRISE_POS_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditHubrisePosIntegrationModalOpen: action.payload,
      };
    case 'SET_EDIT_HUBRISE_POS_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editHubrisePosIntegrationModalData: action.payload,
      };
    case 'SET_IS_CREATE_ORDER_CONDITIONS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateOrderConditionsModalOpen: action.payload,
      };
    case 'SET_CREATE_ORDER_CONDITIONS_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createOrderConditionsModalData: action.payload,
      };
    case 'SET_IS_EDIT_ORDER_CONDITIONS_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditOrderConditionsModalOpen: action.payload,
      };
    case 'SET_EDIT_ORDER_CONDITIONS_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editOrderConditionsModalData: action.payload,
      };
    case 'SET_IS_CREATE_ITEM_ADDITION_GROUP_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateItemAdditionGroupModalOpen: action.payload,
      };
    case 'SET_CREATE_ITEM_ADDITION_GROUP_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createItemAdditionGroupModalData: action.payload,
      };
    case 'SET_IS_EDIT_ITEM_ADDITION_GROUP_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditItemAdditionGroupModalOpen: action.payload,
      };
    case 'SET_EDIT_ITEM_ADDITION_GROUP_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editItemAdditionGroupModalData: action.payload,
      };
    case 'SET_IS_EDIT_CATEGORY_BULK_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditCategoryBulkModalOpen: action.payload,
      };
    case 'SET_EDIT_CATEGORY_BULK_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editCategoryBulkModalData: action.payload,
      };
    case 'SET_IS_CREATE_KIOSK_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateKioskModalOpen: action.payload,
      };
    case 'SET_CREATE_KIOSK_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createKioskModalData: action.payload,
      };
    case 'SET_IS_EDIT_KIOSK_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditKioskModalOpen: action.payload,
      };
    case 'SET_EDIT_KIOSK_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editKioskModalData: action.payload,
      };
    case 'SET_IS_CREATE_TERMINAL_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateTerminalModalOpen: action.payload,
      };
    case 'SET_CREATE_TERMINAL_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createTerminalModalData: action.payload,
      };
    case 'SET_IS_EDIT_TERMINAL_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditTerminalModalOpen: action.payload,
      };
    case 'SET_EDIT_TERMINAL_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editTerminalModalData: action.payload,
      };
    case 'SET_IS_CREATE_PICKUP_STATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreatePickupStationModalOpen: action.payload,
      };
    case 'SET_CREATE_PICKUP_STATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createPickupStationModalData: action.payload,
      };
    case 'SET_IS_EDIT_PICKUP_STATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditPickupStationModalOpen: action.payload,
      };
    case 'SET_EDIT_PICKUP_STATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editPickupStationModalData: action.payload,
      };
    case 'SET_IS_CREATE_MATRIX_POS_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateMatrixPosIntegrationModalOpen: action.payload,
      };
    case 'SET_CREATE_MATRIX_POS_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createMatrixPosIntegrationModalData: action.payload,
      };
    case 'SET_IS_EDIT_MATRIX_POS_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditMatrixPosIntegrationModalOpen: action.payload,
      };
    case 'SET_EDIT_MATRIX_POS_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editMatrixPosIntegrationModalData: action.payload,
      };
    case 'SET_IS_CREATE_CONNECTOR_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateConnectorModalOpen: action.payload,
      };
    case 'SET_CREATE_CONNECTOR_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createConnectorModalData: action.payload,
      };
    case 'SET_IS_EDIT_CONNECTOR_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditConnectorModalOpen: action.payload,
      };
    case 'SET_EDIT_CONNECTOR_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editConnectorModalData: action.payload,
      };
    case 'SET_IS_CREATE_SCANNER_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateScannerIntegrationModalOpen: action.payload,
      };
    case 'SET_CREATE_SCANNER_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createScannerIntegrationModalData: action.payload,
      };
    case 'SET_IS_EDIT_SCANNER_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditScannerIntegrationModalOpen: action.payload,
      };
    case 'SET_EDIT_SCANNER_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editScannerIntegrationModalData: action.payload,
      };
    case 'SET_IS_CREATE_AREA_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateAreaModalOpen: action.payload,
      };
    case 'SET_CREATE_AREA_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createAreaModalData: action.payload,
      };
    case 'SET_IS_EDIT_AREA_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditAreaModalOpen: action.payload,
      };
    case 'SET_EDIT_AREA_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editAreaModalData: action.payload,
      };
    case 'SET_IS_CREATE_DELIVERECT_POS_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCreateDeliverectPosIntegrationModalOpen: action.payload,
      };
    case 'SET_CREATE_DELIVERECT_POS_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        createDeliverectPosIntegrationModalData: action.payload,
      };
    case 'SET_IS_EDIT_DELIVERECT_POS_INTEGRATION_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isEditDeliverectPosIntegrationModalOpen: action.payload,
      };
    case 'SET_EDIT_DELIVERECT_POS_INTEGRATION_MODAL_DATA':
      return {
        ...state,
        ...initialModalDataState, // reset all modal data
        editDeliverectPosIntegrationModalData: action.payload,
      };
    case 'SET_IS_CALL_A_WAITER_MODAL_OPEN':
      return {
        ...state,
        ...initialModalOpenState, // close all modals
        isCallWaiterModalOpen: action.payload,
      };

    default:
      return state;
  }
}
